
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-form-item {
    width: 100%;
  }

  .ant-btn-primary {
    margin-top: 24px;
    padding-left: 42.5px;
    padding-right: 42.5px;
  }
}
