@import "src/assets/style/variables";

.info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 256px;
  height: 100%;
  min-height: 88px;
  background-color: $secondaryDisabled;
  border-radius: 4px;

  &__title {
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: 0.1px;
    font-weight: 500;
    min-width: 208px;
    padding: 0 5px;
    text-align: center;
  }

  &__val {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.1px;
    font-weight: 700;
    color: $secondary;
    text-align: center;
  }

  &__top {
    display: flex;
    justify-content: flex-end;
    width: 100%
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .ant-skeleton-button {
      width: 100% !important;
    }
  }

  &--small {
    .info-block__val {
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  &--large {
    min-width: 400px;
    min-height: 168px;
    //margin-bottom: auto;

    .info-block__content {
      flex-direction: column-reverse;
      flex: 1;
      justify-content: center;
    }
  }
}
