@import "src/assets/style/variables";

.analytics-dashboard {
  //padding: 0 24px;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 130px);

  .analytics-head {
    margin-bottom: 36px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;

    &--mb {
      margin-bottom: 34px;
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}

.info-training {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  min-width: 256px;
  height: 100%;
  min-height: 88px;
  background-color: $secondaryDisabled;
  border-radius: 4px;

  &__title {
    font-size: 12px;
    line-height: 14.4px;
    letter-spacing: 0.1px;
    font-weight: 500;
    min-width: 208px;
    padding: 0 5px;
    text-align: center;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    margin-top: 4px;

    .info-training__val {
      margin: 0 auto;
    }

    .ant-radio-wrapper {
      font-size: 12px;
    }
  }

  &__val {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.1px;
    font-weight: 700;
    color: $secondary;
    text-align: center;
    width: 50%;
  }

  &__flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 15px;
  }

}

