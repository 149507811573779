.base-input {
    position: relative;
    display: flex;
    flex-direction: column;

    .input-search {
        padding-right: 35px;
    }

    input {
        border-radius: 2px !important;
    }

    .input-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;

        img {
            vertical-align: inherit;
        }

    }

    .error {
        display: block;
        width: 100%;
        margin-top: 0.25rem;
        font-size: .875em;
        color: #ff0000;
    }
}
