.label-badge {
    display: inline-flex;
    align-items: center;

    &__box {
        width: 36px;
        min-width: 36px;
        height: 36px;
        border-radius: 50%;
    }
}
