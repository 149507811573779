.edit-structure-position-wrapper {
  display: flex;
  flex-direction: row;
  min-height: 300px;
  min-width: 900px;
}
.edit-structure-position-h5 {
  text-align: center;
}

/* tree */
.recursive-tree {
  flex: 1;
  border-right: 1px solid lightgrey;
  overflow-x: auto;
  margin-bottom: 30px;
}
.tree-item {
  display: flex;
  flex-direction: row;
  margin: 5px;
  cursor: pointer;
}
.tree-children {
  margin-left: 20px;
  border-left: 1px dotted lightgrey;
}
.triangle-right {
  border: 10px solid transparent; 
  border-left: 10px solid #00a79d;
  width: 10px;
  height: 10px;
}
.triangle-down {
  border: 10px solid transparent; 
  border-top: 10px solid #00a79d;
  transform: translate(-25%, 30%);
}
.tree-item-label-active {
  color: #00a79d;
}

/* window */
.table-positions th, td {
  padding-left: 15px;
}
.table-h5, .table-positions {
  width: 100%;
}
.table-h5 {
  margin-bottom: 30px;
}
.table-h5 tr td:nth-child(2), .table-positions tr td:nth-child(2) {
  width: 60%;
}
.break-word {
  max-width: 350px;
  word-wrap: break-word;
}
.window-edit-action {
  flex: 1;
  padding-left: 10px;
}
.actions-structure-positions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}
.btn-actions-structure-positions {
  margin: 0 !important;
}
