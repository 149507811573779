.dist-level-risk {
  min-height: fit-content;
  min-width: 400px;
  height: 100%;
  align-items: center;

  .apexcharts-legend-series {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 122px;
  }

  .apexcharts-legend-text {
    display: inline-block;
    width: 100px;
    text-align: center;
  }
}

.add-measures {
  height: 100%;
  align-items: center;
}

.indent-hazard {
  height: 100%;
  align-items: center;
}
