@import 'ant';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
}

.app {
    display: flex;
    align-items: stretch;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Rubik', sans-serif;
}

table {
    width: 100%;
}

ul {
    margin: 0;
}

nav {
    width: 290px;
    background: rgb(24, 23, 28);
    min-height: 100vh;
    overflow-y: auto;
}

a {
    text-decoration: none;
}

#root {
    width: 100%;
    height: 100%;
}

#content {
    width: calc(100% - 290px);
    height: 100%;
    min-height: 100vh;
}

header {
    position: sticky;
    top: 0;
    z-index: 3;
    background: #fff;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    color: #101331;
}

.menus {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left-menus__items {
    display: flex;
    align-items: center;
}

header a {
    color: #101331;
}

.left-menus__items li {
    margin: 0 15px;
}

.left-menus__items li a img {
    display: block;
    margin: auto;
}

.left-menus__items li a {
    text-align: center;
    display: block;
    padding: 20px 0;
    display: block;
    box-sizing: border-box;
    position: relative;
}

.left-menus__items li a::after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #00A79D;
    opacity: 0;
}

.left-menus__items li a.active {
    opacity: 1;

}

.left-menus__items li a.active::after {
    opacity: 1;

}

/* .account button{
    border: none;
    background-color: transparent;
}
.account button span{
    font-size: 15px;
    margin-right: 10px;
} */
.menu-nav h1 {
    color: #fff;
    font-size: 20px;
    line-height: 1;
    font-weight: 500;
    font-family: 'Rubik';
    letter-spacing: 0.1px;
    padding: 38px 16px;
    box-sizing: border-box;
}

.form-input input,
select,
textarea {
    width: 100%;
    padding: 9px 10px 10px 11px;
    border: 1px solid #757B95 !important;
    font-size: 15px;
    line-height: 1.5;
    color: #101331;
    position: relative;
}

.modal-content.rounded-10.border-2 {
    border-color: rgba(0, 0, 0, 1) !important;
    border-width: 2px;
}

.modal-content input,
.modal-content select {
    border-color: rgba(0, 0, 0, 1) !important;
    border-width: 1px;
    border-radius: 8px;
}

.btn-search {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 0;
    background: transparent;
    z-index: 1;
}

.button-white {
    background: transparent;
    color: #101331;
}

.button-group .button-white {
    color: #101331;
}

nav {
    box-sizing: border-box;
    padding: 0;
}

.navbar {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.menu-nav {
    width: 100%;
    flex: 1;
}

.menu-navbar ul li a,
.menu-navbar ul li div {
    position: relative;
    padding: 10px 0 10px 35px;
    display: block;
    transition: all 0.5s;
    font-family: "GolosTextWebVF", serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #FAF9F9;
}

.menu-navbar ul li a::before {
    width: 8px;
    background: #E0E1DD;
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    height: 100%;
    content: "";
    transition: all 0.5s;
}

.menu-navbar ul li a:hover {
    background: rgba(250, 249, 249, 0.31);
}

.menu-navbar ul li a.active::before {
    background: #E0E1DD;
    opacity: 1;
    transition: all 0.5s;
}

.menu-navbar ul li a.active {
    background: linear-gradient(0deg, rgba(250, 249, 249, 0.31), rgba(250, 249, 249, 0.31)), rgba(250, 249, 249, 0.31);
    transition: all 0.5s;
    padding-left: 54px;

    padding-right: 20px;
}

main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: calc(100% - 88px);
    padding: 23px 0;
}

.main-content {
    height: 100%;
}

.button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 12px;
}

.button-group button {
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    border-radius: 5px;
    border: 0;
    padding: 11px;
    margin-left: 20px;
    max-width: 100%;
}

.btn-blue {
    background: #00A79D;
}

.btn-blue:disabled {
    background-color: #52575c;
    opacity: 0.5;
    cursor: not-allowed;
}

.table-info {
    border-radius: 8px;
    background: #FAFAFA;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
}

.table-info thead {
    background: #FAFAFA;
}

.table-info td {
    padding-top: 7px;
    padding-bottom: 6px;
    padding-right: 18px;
    padding-left: 18px;
}

.table-data {
    min-height: 43px;
}

.table-data select,
.input-form {
    border: 1px solid #DBDDE0;
    height: 43px;
    padding: 10px;
}



/* form modal */
.form-modal {
    padding: 29px 42px 17px 32px;
    background: #fff;
}

.title-form {
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    height: 77px;
    color: #000000;
}


/* 01.06.2022 */
.form-modal {
    position: relative;
    /* margin-top: 20px; */
}

.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    height: auto;
    opacity: 1;
    font-size: 20px;
    font-weight: 1.2;
    color: #fff;
    display: flex;
    border: 0;
    border-radius: 0;
    padding: 18px 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-weight: 500;
    height: 50px;
}

.btn-close img {
    margin-right: 10px;
}

.title-m-f {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.content-form {
    margin-top: 10px;
}

.content-form .nav-modal {
    margin-bottom: 20px;
}

.content-form .nav-modal .nav {
    border: 0;
}

.content-form .nav-modal .nav button {
    border: 0ch;
    border-bottom: 4px solid #F4F5F7;
    color: #101331;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
}

.content-form .nav-modal .nav button.active {
    border-color: #00A79D;
}

.content-form .button-group button {
    height: 50px;
    font-weight: 400;
    font-size: 15px;
    text-align: center;
    margin-left: 20px;
}

.content-form .button-white {
    background: transparent;
    color: #101331;
}

.content-form .button-group button img {
    margin-right: 10px;
}

.table-form {
    /* margin-top: 20px; */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 50px;
    width: 100%;
}

.table-form tr {
    height: 56px;
}

.thead-form {
    background: #FAFAFA;
}

.thead-form th {
    padding: 10px;
    font-weight: 400;
    font-size: 15px;
    color: #000;
}

.tbody-form tr {
    border: 1px solid #DBDDE0;
    padding: 7px 0;
    border-radius: 0 !important;
    background: #fff;
}

.tbody-form tr th,
.tbody-form tr td {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    padding: 7px 10px;
    margin: 0 10px;
}

.tbody-form tr th span,
.tbody-form tr td span {
    display: block;
    padding-left: 14px;
}

.w-60 {
    width: 60%;
}

.w-40 {
    width: 40%;
}

.w-20 {
    width: 20%;
}

.w-10 {
    width: 10%;
}

.btn-quoter span {
    width: 100%;
    display: block;
}

.btn-quoter {
    padding: 25px 25px 30px !important;
    display: block;
    border: 1px dashed #D0D3D8 !important;
    border-radius: 12px !important;
    height: auto !important;
}

.form-input {
    position: relative;
    margin-bottom: 20px;
}

.btn-search {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    border: 0;
    background: transparent;
}

.align-item-flex-end {
    align-items: flex-end;
    display: flex;
}

.align-item-center {
    align-items: center;
    display: flex;
}

.content-form a {
    font-weight: 400;
    font-size: 14px;
    color: #52575C;
}

.button-group .border-radius-none {
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-group .border-radius-none img {
    margin-right: 11px;
}

.tbody-form .lists {
    display: flex;
    align-items: start;
}

.tbody-form a {
    text-decoration: underline;
}

.tbody-form .form-input {
    margin-bottom: 0;
}

.title-box {
    font-weight: 500;
    font-size: 15px;
}

.content-form .button-group .btn-white {
    font-weight: 400;
    font-size: 15px;
    color: #101331;
    width: auto;
    height: auto !important;
    background: transparent;
}

.btn-edit {
    background: none;
    border: 0;
    color: #52575C;
    text-decoration: underline;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.4;
}

.table-data {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
}

.form-group-block {
    background: #fff;
    padding: 7px 20px;
    box-sizing: border-box;
}

.btn.send-notice,
.btn.stop_akk {
    border-radius: 8px;
    margin: 0 5px;
    width: 36px;
    height: 36px;
    padding: 0;
}

.btn.stop_akk {
    background: #18171C;
    color: #ffffff;
}

.form-login {
    /* position: fixed;
    z-index: 111; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-content {
    max-width: 580px;
    width: 580px;
    margin: auto;
    border: 1px solid rgba(221, 221, 221, 0.7);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 15px 0px rgb(221 221 221);
}

.form-title {
    font-size: 3rem;
    text-align: center;
}

.warning-text {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.input-group input {
    min-height: 50px;
    width: 100%;
    border: 1px solid #757B95;
    padding: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.td-text {
    line-height: 1.2 !important;
}

.content-page-error {
    border: none;
    box-shadow: none;
    padding: 30px;
    margin: auto;
    text-align: center;
}

.content-page-error .vvesti-button {
    width: auto;
}

.title-page-errors {
    font-size: 10rem;
}

.sub-title-pages-error {
    font-size: 3rem;
}

.modal-body {
    padding: 10px 20px;
}

.modal-body .modalAddBox-str {
    width: auto;
}

.add-file-square {
    min-width: 164px;
    min-height: 84px;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: 0;
}

.d-none-icon>div>.logo {
    display: none;
}

.modalAddBox {
    text-align: left;
    overflow-y: auto;
}

.button-transparent {
    border: none;
    background-color: transparent;
    text-decoration: underline;
}

.modal-step-2 {
    position: fixed;
    z-index: 999999;
    background-color: #0009;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.modal-info {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: block;
    padding: 27px 20px 44px 27px;
    box-sizing: border-box;
}

.mb-50 {
    margin-bottom: 50px;
}

.mt-50 {
    margin-top: 50px;
}

.title-modal-form {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-transform: uppercase;
}

.container-small {
    max-width: 562px;
}

.container-medium {
    max-width: 750px;
}

.modal-fias {
    display: none;
}

.modal-fias.active {
    display: block;
    overflow: auto;
}

.modal-backdrop.show {
    z-index: 1;
}

.pl-1 {
    padding-left: 2rem;
}

.form-control.rounded-0.active {
    border: 1px solid #FF0000 !important;
}

.b-red {
    border: 1px solid #FF0000 !important;
}

/*html, body {*/
/*    height: 100%;*/
/*    background-image: linear-gradient(-105deg, #009acc, #363795);*/
/*}*/
.spinner {
    animation: rotate 2s linear infinite;
    z-index: 2;
    margin: -25px 0 0 -25px;
    width: 40px;
    height: 40px;
    position: relative;
    left: 30px;
    top: 30px;
}

.spinner .path {
    stroke: #93bfec;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}


.experts-form {
    margin-top: 10px;
}

.experts-form h3 {
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
}

.experts-list {
    border: 1px solid #757B95;
    border-radius: 2px;
    max-height: 220px;
    overflow-y: auto;
    padding: 20px;
}

.experts-item {
    font-weight: 400;
    font-size: 15px;
    line-height: 3;
    margin-bottom: 5px;
}

.dangerous-item {
    line-height: 1;
    margin-bottom: 5px;
}

.bigLength {
    white-space: nowrap;
    /* Запрещаем перенос строк */
    overflow: hidden;
    /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis;
    /* Добавляем многоточие */
}


.form-control.rounded-0.telll.active {
    border: 1px solid #FF0000;
}

.css-x19aeh-control {
    width: 100%;
}

.css-x19aeh-control.telll.active {
    border: 1px solid #FF0000;

}

.searchWrapper {
    border: none !important;
    padding: 10px 0 0 0;
}

.measure-form .searchWrapper input,
select {
    border: 1px solid #ced4da !important;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
}

.measure-form .chip,
.singleChip {
    white-space: pre-wrap;
}

.multiselect-border {
    border: 1px solid #ced4da;
}

.multiselect-height {
    height: 38px;
}

.my-multiselect .chip {

    background: #fff;
    color: black;
    font-size: 16px;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    padding-right: 20px !important;
}

.my-multiselect .closeIcon {
    position: absolute;
    right: 5px;
    background-color: #2563eb;
}

.sout-numbers {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    width: 80px;
}

.deleteDangerBody {
    position: relative;
}

.deleteDanger {
    position: absolute;
    left: -25px;
}

.delete-card-work-button {
    width: 195px;
    height: 50px;
    background: #ca4406;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
}

.delete-card-work-button:hover,
.delete-card-work-button:active {
    color: #ca4406;
    border-color: #ca4406;
}

.delete-card-work-button:focus {
    width: 195px;
    height: 50px;
    background: #ca4406;
    color: white;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #ffffff;
}


#multiselectContainerReact input {
    width: 250px !important;
}

.dangerous-influence-wrap {
    padding-top: 4px;
}

.dangerous-influence-wrap #multiselectContainerReact input {
    width: 100% !important;
}

.ml_auto {
    margin-left: auto;
}

.mb_16 {
    margin-bottom: 16px;
}

.editUser {
    font-size: 14px;
    line-height: 14px;
    border-bottom: 1px solid #1E84EB;
    text-decoration: none;
    color: #1E84EB;
    margin: 0 15px;
}

.editUser:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    cursor: pointer;
}

.userslist td,
.roleslist td,
.userlogs td {
    font-family: "Golos Text VF", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

table.roleslist {
    margin-top: 80px;
}

table.userlogs {
    margin-top: 30px;
}

.roleslist thead tr,
.userlogs thead tr {
    border-bottom: 3px solid #495162;
    font-weight: 500;
}

.roleslist thead th,
.userlogs th {
    font-weight: 500;
}
