
.indent-hazard {
  &__wrap {
    display: flex;

    .ant-btn-text {
      text-align: start;
    }
  }
}
