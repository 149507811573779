
.high-level-risk {
  width: 100%;
  min-width: 100%;
  height: 100%;
  align-items: center;

  .apexcharts-xaxis {
    transform: translateX(10px);
  }
}
