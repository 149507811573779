@import "src/assets/style/variables";

.chart-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  background-color: $blockBg;
  border-radius: 8px;
  padding: 10px 10px;

  &__title {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1px;
    text-align: center;
    max-width: 370px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    margin-bottom: 8px;

    &-col {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }
  .active-type {
    color: $secondary
  }

  &__chart {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 300px;
    width: 100%;
    min-height: 200px;

    .ant-skeleton-image {
      width: 300px !important;
      height: 200px !important;
    }

  }
}
