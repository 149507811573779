.management-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .base-input-search {
      width: 100%;
      max-width: 600px;
      height: 40px;
      margin-left: auto;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 55px;
    color: #101331;
  }

  &__stat {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 3px;
      font-size: 20px;
      font-weight: 700;
      line-height: 43px;
      color: #000000;
    }
  }

  &-hazard-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__wrap {
      display: flex;
      align-items: center;

      .management-results__stat-title {
        margin-right: 16px;
      }
    }
  }
}
