.myModal{
    display: none;
    position: fixed;
    background: #0005;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    max-height: 100vh;
    overflow-y: auto;
}
.myModal.active{
    display: block;
    overflow: overlay;
}
.modal-content{

}
.myModal::-webkit-scrollbar{
    display: none;
}
.mt-50{
    margin-top: 50px;
}
.mb-50{
    margin-bottom: 50px;
}