@import 'variables.scss';

//BUTTON

.ant-btn-lg {
  line-height: 19.2px;
}

.ant-btn-primary {
  color: $white;
  background-color: $primary;
  box-shadow: none !important;

  &:hover {
    background-color: $primaryHover !important;
  }

  &:disabled {
    color: $textDisabled;
    background-color: $primaryDisabled !important;
  }
}

.ant-btn-default {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: none !important;

  &:hover {
    color: $white !important;
    background-color: $secondaryHover !important;
    border-color: $secondaryHover !important;
  }
}

.ant-select-selector {
  box-shadow: none !important;
}

// INPUT
.ant-input-affix-wrapper-focused,
.ant-picker-focused {
  box-shadow: none;
}

.ant-picker-input {
  input::placeholder {
    color: $textLight !important;
    opacity: 0.6 !important;
  }
}

.ant-input {
  &::placeholder {
    color: $textLight !important;
    opacity: 0.6 !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $primary;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #ffffff;
}

.ant-form-item-explain-error {
  font-size: 10px;
  line-height: 10px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input {
  border-color: $primary;
  line-height: 24px;

  &:hover {
    border-color: $inputHover;
  }

  &:focus,
  &:active {
    border-color: $inputFocus;
  }
}

.ant-tree .ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-row-expand-icon-spaced {
  display: none;
}

// SELECT

.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: $primary;

    //&:hover {
    //  border-color: $inputHover;
    //}
    //
    //&:focus,
    //&:active {
    //  border-color: $inputFocus;
    //}
  }

}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover {
  .ant-select-selector {
    border-color: $inputHover;
  }
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus {
  .ant-select-selector {
    border-color: $inputFocus;
  }
}

.ant-select-open:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer),
.ant-select-open:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover, {
  .ant-select-selector {
    border-color: $inputFocus;
  }
}

.confirm-automatic-evaluation {
  .ant-modal-confirm-paragraph {
    row-gap: 0;
  }
}


.training-table-description {
  width: 700px;
}
