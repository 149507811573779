@import "src/assets/style/variables";

.auth-form {
  display: flex;
  flex-direction: column;
  width: 100%;


  &__link {
    font-size: 16px;
    line-height: 20px;
    text-decoration: underline;
    color: $secondary;
    padding: 0 20px;
  }

  &__footer {
    margin: 32px 0;
    display: flex;
    justify-content: space-between;

    .ant-btn {
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
