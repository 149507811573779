.td-text-employers {
  vertical-align: middle;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #52575C;
}
.mnbtm-4 {
  margin-bottom: 4px;
}
.ml {
  margin-left: 6.28%;
}
