#leftTablePosition {
  padding-left: 0.5rem !important;
}

.h-129 {
  height: 129px;
}
.fz-13 {
  font-size: 13px
}
.va-center {
  vertical-align: middle;
}
.redText {
font-weight: 400;
font-size: 16px;
line-height: 122%;
text-transform: uppercase;
color: #DF2525;
}
.class-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.class-title2 {
color: #000000;
  font-style: normal;
  font-weight: 500;
font-size: 15px;
line-height: 45px;
height: 10px;
  display: flex;
  align-items: center;
  padding-left: 35px;
margin-top: 34px;
}
.modalAddBox {
width: 479px;
height: 229px;
/* left: 25px;
top: 99px; */
background: #F4F5F7;
border-radius: 2px;
}

.modalAddBox-new {
  width: 95.5%;
  height: 80vh;
  /* left: 25px;
  top: 99px; */
  background: #F4F5F7;
  border-radius: 2px;
  overflow: auto;
}
.modalOpenEditBox {
  width: 341px;
  height: 197px;
  /* left: 25px;
  top: 99px; */
  background: #F4F5F7;
  border-radius: 2px;
  }
.modalWindow {
/* width: 616px !important; */
/* --bs-modal-height: 428px;  */
--bs-modal-width: 616px;
}
.modalWindowOpenEdit {
  --bs-modal-width: 790px;
}
.href_as_button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  font-size: 13px;
}
.pdng-rght-0 {
  padding-right: 0;
}
.pdng-rght-89 {
  padding-right: 8.9rem;
}
.pddng-lft-0 {
  padding-left: 0px;
}
.pddng-lft-280 {
  padding-left: 28.0rem;
}
.pddng-lft-319 {
  padding-left: 31.9rem;
}
.pddng-tp-0 {
  padding-top: 0rem;
}
.pddng-tp-1 {
  padding-top: 3px;
}
.pddng-lft-15 {
  padding-left: 2.5rem;
}
.pddng-lft-5 {
  padding-left: 0.5rem;
}
.pddng-lft-10 {
  padding-left: 1.5rem;
}
.pddng-lft-25 {
  padding-left: 4rem;
}
.pddng-btm-10 {
  margin-bottom: 1.5rem;
}
.pddng-btm-8 {
  margin-bottom: 0.8rem;
}
.pddng-lft-65 {
  padding-left: 6.5rem;
}

/* Check */
/* .check {

}
.check_input {
  position: absolute;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.check_box {
  position: absolute;
  width: 1em;
  height: 1em;
  background-image: url(../image/check.svg);
} */
