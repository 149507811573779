
.analytics-head {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &__block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 260px;
    margin-right: 40px;

    &-label {
      font-size: 14px;
      font-weight: 500;
    }
  }
}
