.modalWidthObshieSvedeniya {
  --bs-modal-width: 1131px;
  --bs-modal-heigth: 1111px;
}
.class-title-adresss {
font-weight: 700;
font-size: 15px;
line-height: 24px;
}

.vvesti-button {
  width: 100px;
  height: 38px;
  color: white;
  background: #00A79D;
  border-radius: 5px;
}
/* .download-button {
width: 100px;
height: 38px;
color: black;
border: 1px solid #00A79D;
border-radius: 5px;
} */

input::placeholder{
   opacity: 0.3 !important;
}
